import axios from "axios"
import { config } from "../../config"


const getProductswithCategory = (priceId) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.GRIPHOOK_BACKEND_URL}/plans/getProductswithCategory?priceId=${priceId}`)
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const getProductswithCategoryAsInput = (key, interval) => {

    return new Promise((resolve, reject) => {

        if (interval) key.interval = interval;

        console.log(key);

        axios.get(`${config.GRIPHOOK_BACKEND_URL}/plans/getProductswithCategory?params=${JSON.stringify(key)}`)
            .then(res => {
                resolve(res?.data?.data);
            })
            .catch(() => {
                resolve(false);
            });
    });
}


const getProduct = (name) =>{
    return new Promise((resolve, reject)=>{
        axios.get(`${config.GRIPHOOK_BACKEND_URL}/plans/getPriceIdByName?plan=${name}`)
        .then(res=>{
            resolve(res?.data?.data)
        })
        .catch(()=>{
            resolve(false);
        })

    })
}

export {
    getProductswithCategory,
    getProductswithCategoryAsInput,
    getProduct
};
