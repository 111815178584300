import {
  Actions_,
  Alert_,
  Builder_,
  CardsSixty,
  CardsTools,
  Cta_,
  Dropdown_,
  Filters_,
  Form,
  Full_Card_Loader,
  Grey_,
  Grey_Link,
  Input_,
  Main_,
  Main_Plain,
  On_Click_Card,
  PageFull,
  PageTitle,
  PageTools,
  Partial_Loader,
  Section_Filters_Search,
  Section_Title,
  Sixty_Card_Loader,
  Slider_,
  Sub_Status,
  TBody,
  TD,
  TH,
  THead,
  TR,
  TRH,
  Table,
  TableDesktop,
  ThemeContext,
  logo,
} from "monica-alexandria";
import React, { useEffect, useState, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";
import { get_plans, plan_subscribers } from "../../../services/api/Mocks";
import Toolbar_ from "../../partials/navigation/Toolbar_";
import { Clipboard, PlansFilters, PlansSlider, PlansStatus } from "./PLANS.css";
import { send_payment_link } from "../../../services/api/Mocks";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";
import { AlertContext } from "monica-alexandria";
import Slider_Current_Plan from "./partials/Slider_Current_Plan";
import { useNavigate } from "react-router-dom";
 
export default function PLANS() {
  const protocol = process.env.REACT_APP_GRIPHOOK_FRONTEND_PROTOCOL;
  const url = process.env.REACT_APP_GRIPHOOK_FRONTEND_HOST;
  const port = process.env.REACT_APP_GRIPHOOK_FRONTEND_PORT;
  const griphookFrontURL = `${protocol}${url}${port}`;
 
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
 
  // useReducer is like useState, but you don't need to use the spread operator e.g. setState({...oldData, newData})
  // instead, you need to write it like this: setState({newData})
  const [currentPlan, setCurrentPlan] = useReducer((a, b) => ({ ...a, ...b }), {
    activeSlider: "",
    slider: false,
  });
  const [users, setUsers] = useState([]);
  const [langs, setLangs] = useReducer((a, b) => ({ ...a, ...b }), {
    targetLang: "el",
    displayedLang: "el",
  });
 
  // useState: handle primitives & arrays, useReducer: handle objects
  const [plans, setPlans] = useState([]);
  const [paymentLink, setPaymentLink] = useState();
 
  function prettifyCurrency(currency) {
    if (currency == "eur") return "€";
    else return currency;
  }

  const setSlider = (plan) => {
    let mode;
    let paramsType = "";
    let trial = ``;
 
    switch (plan?.type) {
      case "recurring": {
        mode = "subscription";
        break;
      }
      case "one_time": {
        mode = "payment";
        break;
      }
      case "setup": {
        mode = "setup";
        break;
      }
      default:
        mode = "subscription";
    }
 
    if (plan?.product?.metadata.trial) trial = `&trial=${plan?.product?.metadata.trial}`;
 
    if (plan?.type == "recurring")
      paramsType = `&interval=${plan?.recurring?.interval}`;
    else if (plan?.type == "one_time")
      paramsType = `&type=one_time`;
 
    let name = plan?.product?.name.replace(/\s/g, "%20");
 
    setPaymentLink(
      `${griphookFrontURL}/${langs.displayedLang}/?priceId=${plan?.id}&mode=${mode}&name=${name}&amount=${plan?.unit_amount}${trial}${paramsType}`
    );
    setCurrentPlan({
      id: plan?.id,
      name: plan?.product?.name,
      slider: true,
      activeSlider: plan?.id,
    });
    plan?.subscribers.map((subscriber) => {
      if (subscriber?.users) setUsers(subscriber?.users);
      else setUsers([]);
    });
  };
 
  useEffect(() => {
    get_plans()
      .then((res) => {
        setPlans(res.data);
      })
      .catch((err) => {});
  }, []);
  return (
    <PageFull className={currentPlan.slider ? "HideScroll" : ""}>
      {currentPlan.slider && (
        <Slider_
          onClose={() => setCurrentPlan({ slider: false })}
          onClick={() => setCurrentPlan({ slider: !currentPlan.slider })}
          text={t("Close")}
          title={currentPlan.name}
        >
          {currentPlan.activeSlider == currentPlan.id ? (
            <Slider_Current_Plan
              paymentLink={paymentLink}
              plan={currentPlan.id}
              setPaymentLink={setPaymentLink}
              subscribers={users}
            ></Slider_Current_Plan>
          ) : null}
        </Slider_>
      )}
      <Alert_ />
      <PageTitle>
        <Grey_Link noPadding iconLeft="Back" onClick={() => navigate(-1)} />
        <h1>{t("All plans")}</h1>
      </PageTitle>
      {plans?.length ?
        <TableDesktop>
          <Table>
            <THead>
              <TRH>
                <TH></TH>
                <TH>#</TH>
                <TH>{t("Plans")}</TH>
                <TH>{t("Price")}</TH>
              </TRH>
            </THead>
            <TBody>
            {plans?.map((plan, i) => ( 
              <TR key={i}>
                <TD>
                  <Main_Plain onClick={() => setSlider(plan)} noPadding iconLeft="Edit"/>
                </TD>
                <TD>{i + 1}</TD>
                <TD>{plan?.product?.name}</TD>
                <TD>{`${prettifyPrice(plan?.unit_amount)}${prettifyCurrency(plan?.currency)}`}</TD>
              </TR>
            ))}
            </TBody>
          </Table>
        </TableDesktop>
      :
        <Partial_Loader />
      }
      {/* {plans?.length > 0 ? (
        <CardsTools>
          {plans?.map((plan, i) => (
            <On_Click_Card
              key={i}
              imgBehave="Contain"
              onClick={() => setSlider(plan)}
              // header={plan?.subscribers.toString()}
              img={ plan?.product?.metadata?.category ? logo[plan?.product?.metadata?.category][theme] : null}
              subtitle={plan?.product?.name}
              title={`${prettifyPrice(
                plan?.unit_amount
              )}${prettifyCurrency(plan?.currency)}`}
            >
              <PlansStatus>
                {plan?.subscribers?.map((item, i) => (
                  <Sub_Status
                    key={i}
                    text={item?.number}
                    status={item?.status}
                  />
                ))}
              </PlansStatus>
            </On_Click_Card>
          ))}
        </CardsTools>
      ) : (
        <Full_Card_Loader />
      )} */}
    </PageFull>
  );
}