import React, { useEffect, useState } from "react";
import { AddressElement, useElements, useStripe} from "@stripe/react-stripe-js";
import styled from "styled-components";
import { get_customer } from "../../services/api/Subscriptions";
import store from "../../redux/store";
import { Builder_, Checkbox_, Input_ } from "monica-alexandria";
import { useTranslation } from "react-i18next";
import { checkVAT, countries } from 'jsvat';
const vat_CC = require('../../config/vatCountryCodes').vatCountryCodes;

const CheckoutAddress = styled.div``;
const CheckoutExtraInput = styled.div`
  margin-top: 1.5rem;

  input{
    background: var(--light);
    border: 1px solid var(--greyLight);
    color: var(--black) !important;
    border-radius: 16px;
  }

  .Invoice{
    margin-bottom: .5rem;

    input{
      background: var(--light) !important;
      color: var(--black) !important;
    }
  }
`
export default function (props) {
  // Create the Address Element in billing mode
  const elements = useElements()
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true)

  const user = store.getState().user.user;
  

  const customer = window?.sessionStorage?.getItem('customer')
  const defaultValuesObj =  JSON.parse(window.sessionStorage.getItem('billing'))  || {}
  delete defaultValuesObj?.name
  const query = JSON.stringify(props?.queryParams) !== '{}' ? props?.queryParams : store.getState().params 
  

  const check_if_customer_exists = () =>{
    get_customer(user?.sub, query.category)
    .then(res =>{
      window?.sessionStorage?.setItem('customer', res?.customer?.customerId)
      window.sessionStorage.setItem('billing', JSON.stringify({phone: res?.customer?.phone, 
        firstName: res?.customer?.name.split(' ')[0], 
        lastName: res?.customer?.name.split(' ')[1],
        email: res?.customer?.email,
        address: res?.customer?.address  
      }))
    })
    .catch(err=> console.log(err))
  }
  
  const addressOptions = {
    mode: "billing",
    display: {
      name: "split",
    },
    fields: {
      phone: "always",
    },
    validation: {
      phone: { required: "always" },
    },
    defaultValues: defaultValuesObj
  }

  const handleAdressForm = (e) =>{
    if(!loading){
      // elements.getElement(AddressElement).getValue()
      props?.setBillingFields(e.value)
    }
  }

  const validateVat = (vatValue) => {
    // Combine best practices from previous responses:
    const countryCode = vat_CC[`${props?.billingFields?.address?.country}`]; // Replace with your actual country code (avoid hardcoding if possible)
    const trimmedVatValue = vatValue.trim(); // Trim leading/trailing whitespace
  
    // Use optional chaining and nullish coalescing to handle potential undefined values:
    const fullVatNumber = `${countryCode}${trimmedVatValue}`;
    const check = checkVAT(fullVatNumber, countries) || { isValid: false }; // Handle potential undefined check result
  
    // Update messages and billing fields based on validation result:
    // const helpers = !check.isValid ? [t("VAT is not valid")] : [];
    // const successes = check.isValid ? [t("VAT is valid")] : [];

    const helpers = [];
    const successes = [];

    props.setMessages({
      ...props.messages,
      helpers,
      successes,
    });
    props?.setBillingFields({
      ...props?.billingFields,
      vat: vatValue,
    });
    if(check.isValid) {
      props?.setBillingFields({
        ...props?.billingFields,
        vat: fullVatNumber,
      });
    }
  };
  
  


  const handleCheckboxChange = () => {
    props.setShowVatInput(prevState => !prevState);
  };
  


  useEffect(()=>{
    if(customer === 'undefined' || !customer || customer == null || customer == undefined){
      check_if_customer_exists()
    }      
  }, [])

  useEffect(() => {
    if (elements && elements.getElement(AddressElement)) {
      elements.getElement(AddressElement).on('ready', function () {
        setLoading(false);
      });
    }
  }, [elements]);
  
    return (
      <CheckoutAddress>
        <AddressElement onChange={(e) => handleAdressForm(e)} options={addressOptions} />
        {/* TODO: */}
      <CheckoutExtraInput>
          <Checkbox_ checked={props.showVatInput} className="Invoice" text={t("I wish an invoice")} onChange={handleCheckboxChange}/>
          {props.showVatInput ? 
          <Builder_>
            <Input_ 
              placeholder={t("VAT number")} 
              onChange={(e)=>validateVat(e.target.value)} 
              helpers={props.messages?.helpers} 
              successes={props.messages?.successes} 
              errors={props.messages?.errors} 
              value={props?.billingFields?.vat}
            />
          </Builder_>
          :
          null
        }
      </CheckoutExtraInput>
      </CheckoutAddress>
    );
}
