import { Card, Grey_Link, Sub_Status } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SubscriptionHistoryBlock = styled(Card)`
  padding: 1rem;
  margin-bottom: 1rem;

  p{
    font-size: 1.2rem;
  }

  h6{
    font-size: 1.2rem;
    color: var(--greyDark);
    text-align: right;
  }
`

const SubscriptionHistoryBlockPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export default function Subscription_History_Block(props) {

  const {t} = useTranslation()

  return (
    <SubscriptionHistoryBlock>
      <h6>{props.status}</h6>
      <SubscriptionHistoryBlockPrice>
        <h2>{props.currency}</h2>
        <h2>{props.price}</h2>
      </SubscriptionHistoryBlockPrice>
      <p>{props.date}</p>
      
      {/* Dates are not translated */}
      {/* <Grey_Link text={t("Get invoice")} iconLeft="File"/> */}
    </SubscriptionHistoryBlock>
  )
}
