import axios from "axios"
import { config } from "../../config"

// const getTransactions = (customer) => {
//     return new Promise((resolve, reject) => {
//         console.log(customer)
//         let params = ``;
//         // if ( input ) params += `input=${input}&` 
//         // if ( filters ) params += `filters=${filters}&` 
//         // if ( sorting ) params += `sorting=${sorting}` 
//         if( customer ) params += `customer=${customer}`
//         axios.get(`${config.GRIPHOOK_BACKEND_URL}/transactions/getTransactions?${params}`)
//         .then(res => {
//             resolve(res?.data?.data)
//         })
//         .catch(() => {
//             resolve(false);
//         })
//     })
// }

const getTransactions = (customer) => {
    return new Promise((resolve, reject) => {
        console.log(customer)
        let params = ``;
        // if ( input ) params += `input=${input}&` 
        // if ( filters ) params += `filters=${filters}&` 
        // if ( sorting ) params += `sorting=${sorting}` 
        if( customer ) params += `customer=${customer}`

        axios.get(`${config.GRIPHOOK_BACKEND_URL}/transactions/getStripeTransactions?${params}`)
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

export {
    getTransactions,
};
