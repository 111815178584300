
// Displays price in a more marketable form
export const prettifyPrice = (price, interval) => {
    if (!price) return "0,00";
    if(interval && interval==="year") price = price/12
    const parsedPrice = parseFloat((price.toString().replace(',', '.'))/100).toFixed(2);
    const [whole, decimals] = parsedPrice.split('.');
    const paddedDecimals = decimals.padEnd(2, '0');

    return `${whole.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${paddedDecimals}`;

}