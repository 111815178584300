import { Builder_, CardsForty, CardsSixty, IconSvg, logo, Main_, Main_Plain, On_Click_Card, PageAlwaysVisible, PageSixty, PageTitle, Slider_, ThemeContext, Unlock_Card } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { get_my_subscriptions } from '../../services/api/Mocks';
import { MyPlansActions, MyPlansFooter, MyPlansStatus } from './MY_PLANS.css'
import store from '../../redux/store'
import { prettifyPrice } from '../../helpers/prettifyFunctions';
import { create_portal_session } from '../../services/api/Mocks';
import i18n from '../../localization/i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const clients = require('../../config/index').config;

const MyPlans = styled.div`

  .blue{
    height: 15rem;
    width: 100%;
    background: blue;
  }
`

export default function MY_PLANS() {

  const [slider, toggleSlider] = useState(false);
  const {theme} = useContext(ThemeContext)
  const [activeSlider, setActiveSlider] = useState('');
  const [mySubs, setMySubs] = useState(false);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const userId = store.getState()?.user?.user?.sub;
  const lang = i18n.language ? i18n.language : 'en';

  const redirect =(category, tools) =>{
    console.log('ttt',tools)
    let name;
    switch(category){
      case 'lois' : 
        const matchingAdsnap = tools?.find((subscription) => subscription?.category === category && subscription?.property==='base');
        name = matchingAdsnap ? matchingAdsnap.name : 'Adsnap';  

        console.log('amtch', matchingAdsnap)
        window.location.assign(`${clients['griphook']}/${lang}/subscription-adsnap?name=${name}&category=${category}`)
      break;
      case 'dailyprofit': 
        window.location.assign(`${clients['griphook']}/${lang}/subscription-questad?name=Questad&category=${category}`)
      break;
      case 'bond':
        const matchingTool = tools?.find((subscription) => subscription.category === category);
        name = matchingTool ? matchingTool.name : 'Tailormade';     
        window.location.assign(`${clients['griphook']}/${lang}/subscription?name=${name}&category=${category}`)
      break;
      case 'monica': return "Mini Website"
      break;
      default: return 'Your Plan'
    }
  }
  const planName =(category, tools) =>{
    console.log('ttt',tools)
    switch(category){
      case 'lois' : 
      const matchingAdsnap = tools?.find((subscription) =>subscription.category === category && subscription.property==='base');
      return matchingAdsnap ? matchingAdsnap.name : 'Adsnap';   
      break;
      case 'dailyprofit': return "Questad"
      break;
      case 'bond':
        const matchingTool = tools?.find((subscription) => subscription.category === category);
        return matchingTool ? matchingTool.name : 'Tailormade';     
      break;
      case 'monica': return "Mini Website"
      break;
      default: return 'Your Plan'
    }
  }
  const isActive = ( tools ) =>{
    return tools.every(tool => tool.active === true);
  }

  useEffect(() => {
    get_my_subscriptions(userId)
      .then(data => {
        console.log('data', data?.data)
        if (!data?.data) setMySubs([])
        else {
          console.log(data)
          const updatedSubs = data?.data.map(category => ({
              category: category.category,
              tools: category?.tools,
              hasActiveSubscription: category.tools.some(tool => tool.active)
          }));
          // Sort the updatedSubs array based on a predefined order
          const orderedSubs = updatedSubs.sort((a, b) => {
              const order = ['dailyprofit', 'bond', 'lois']; // Define your desired category order here
              return order.indexOf(a.category) - order.indexOf(b.category);
          });
          setMySubs(orderedSubs);
      }      })
      .catch(err => {})
  }, [])

  console.log('my subs', mySubs)

  if (!mySubs) return <></>
  return (
    <MyPlans>
      <Helmet>
        <title>{t('Doitforme | My plans')}</title>
      </Helmet>
      {/* {slider & activeSlider == '1000' ?
          <Slider_ 
            title = '1000 - Tailormade'
            onClose = {() => toggleSlider(false)}
            text = {t('Done')}
          >
          <Builder_ 
              title = {t('Configure plan')}
          >
              <Main_ 
                  text={t('Go to stripe')}
              />
          </Builder_>
      </Slider_>
        :
          null
      } */}
      <PageAlwaysVisible>
        <PageTitle>
          <h1>{t('My subscriptions')}</h1>
        </PageTitle>
          <CardsSixty>
          {mySubs?.map((sub, i) =>  (

           <On_Click_Card 
            img = {logo[sub.category]?.[theme]}
            imgBehave="Contain"
            title = {`${planName(sub?.category, sub?.tools)} ${sub?.temp ? '(dummy)': ''}`}
          >
            <MyPlansFooter>
              <MyPlansStatus variant={isActive(sub?.tools) ? 'Active' : "Inactive"}>
                {/* <picture></picture> */}
                {isActive(sub?.tools) ? 
                  <h6>{t('Active')}</h6>
                  : <h6>{t('Inactive')}</h6>
                }
              </MyPlansStatus>
              <Main_Plain
                iconLeft='Edit'
                text = {t('Click to edit')}
                noPadding
                onClick = {()=> redirect(sub?.category, sub?.tools)}
              />
            </MyPlansFooter>
            <MyPlansActions>
              
            </MyPlansActions>
          </On_Click_Card>
          ))}
          {!mySubs?.some(sub => sub.category === 'lois') || mySubs?.some(sub => sub.category === 'lois' && !sub?.hasActiveSubscription) ?
            <Unlock_Card
                iconBuy="Lock"
                onBuy= {()=>window.location.assign(`${clients['griphook']}/${lang}/checkout-details-adsnap?category=lois`)}
                textBuy="Unlock"
                title="AdSnap"
                imgBehave="Contain"
                img={logo.lois[theme]}
              /> : null}
            {!mySubs?.some(sub => sub.category === 'dailyprofit') || mySubs?.some(sub => sub.category === 'dailyprofit' && !sub?.hasActiveSubscription) ?
            <Unlock_Card
                iconBuy="Lock"
                onBuy= {()=>window.location.assign(`${clients['dailyprofit']}/${lang}`)}
                textBuy="Unlock"
                title="QuestAd"
                imgBehave="Contain"
                img={logo.dailyprofit[theme]}
              /> : null}
          </CardsSixty> 
      </PageAlwaysVisible>
    </MyPlans>
  )
}

