import axios from "axios"
import { config } from "../../config"


const toggleSubscription = (subscriptionId, action) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/subscriptions/toggle_subscritpion`, {
            data: {
                subscriptionId: subscriptionId, 
                action: action
            }
        })
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}



const fetch_cancel_at_period_end = (subscriptionId) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/subscriptions/fetch_cancel_at_period_end?subscriptionId=${subscriptionId}`)
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const create_customer = (customer, email, userId, category) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/create_customer`,{
            customer: customer,
            email: email,
            userId: userId,
            category
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_customer = (userId, category) =>{
    return new Promise((resolve, reject) => {
        axios.get(`${config.GRIPHOOK_BACKEND_URL}/cart/get_customer?userId=${userId}&category=${category}`)
        .then(res => {
            console.log('res',res?.data)
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const calculate_tax = (customer,cart, coupon) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/calculate_tax`,{
            customer: customer,
            products: cart,
            coupon: coupon
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const create_confirm_payment_intent = (customer, cart, trial, userId, paymentMethodId, amount, currency) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/payment_intent?customer=${customer}`,{
            cart: cart,
            trial: trial,
            userId: userId,
            paymentMethodId: paymentMethodId,
            amount: amount,
            currency: currency
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const create_confirm_subscription = (customer, cart, trial, userId, email, paymentMethodId, quest, coupon, billing) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/trial_subscription?customer=${customer}`,{
            cart: cart,
            trial: trial,
            userId: userId,
            email: email, 
            paymentMethodId: paymentMethodId,
            quest: quest,
            coupon: coupon,
            billing: billing
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const create_confirm_subscription_no_card = (customer, customerData, cart, trial, userId, email, paymentMethodId, quest, coupon,) =>{
    return new Promise((resolve, reject) => {

        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/trial_no_card_subscription`,{
            cart: cart,
            trial: trial,
            userId: userId,
            email: email, 
            paymentMethodId: paymentMethodId,
            quest: quest,
            coupon: coupon,
            customer: customerData,
            customerId: customer
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const coupon_validation = (coupon, category, customer) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${config.GRIPHOOK_BACKEND_URL}/cart/validate_coupon`,{
            coupon: coupon,
            customer: customer,
            category: category
        })
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

export {
    toggleSubscription,
    fetch_cancel_at_period_end,
    create_customer,
    get_customer,
    calculate_tax,
    create_confirm_payment_intent,
    create_confirm_subscription,
    coupon_validation,
    create_confirm_subscription_no_card
};
