import {
  Actions_,
  Red_,
  Builder_,
  CardsSixty,
  Dropdown_,
  Grey_,
  Input_,
  Main_Plain,
  On_Click_Card,
  Section_Title,
  ThemeContext,
  AlertContext,
} from "monica-alexandria";
import styled from "styled-components";
import React, { useEffect, useState, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Clipboard } from "../PLANS.css";
import {
  delete_plan,
  plan_subscribers,
  send_payment_link,
} from "../../../../services/api/Mocks";

const SliderCurrentPlan = styled.div``;

export default function Slider_Current_Plan(props) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  // useReducer is like useState, but you don't need to use the spread operator e.g. setState({...oldData, newData})
  // instead, you need to write it like this: setState({newData})
  const [langs, setLangs] = useReducer((a, b) => ({ ...a, ...b }), {
    targetLang: "el",
    displayedLang: "el",
  });

  // useState: handle primitives & arrays, useReducer: handle objects
  const [targetEmail, setTargetEmail] = useState();
  const [targetName, setTargetName] = useState();
  const { alerts, setAlerts } = useContext(AlertContext);
  const [users, setUsers] = useReducer((a, b) => ({ ...a, ...b }), []);

  const langItems = [
    { text: t("Greek"), value: "el" },
    { text: t("English"), value: "en" },
    { text: t("German"), value: "de" },
    { text: t("Italian"), value: "it" },
  ];

  const copyLink = () => {
    navigator.clipboard.writeText(props.paymentLink);

    const message = (t("Your link has been copied"));
    const status = "Success";
    alertMessage(message, status);
  };

  const alertMessage = (message, status) => {
    setAlerts([
      ...alerts,
      {
        message: {
          text: t(message),
          status: status,
        },
      },
    ]);
    setTimeout(() => {
      setAlerts([]);
    }, 8000);
  };

  const sendEmail = () => {
    if (targetEmail.length != 0) {
      send_payment_link(targetEmail, langs.targetLang, props.paymentLink)
        .then((res) => {
          const message = (t("Your email has been sent..."));
          const status = "Success";
          alertMessage(message, status);
        })
        .catch((err) => {
          const message = (t("There was an error. Please try again later..."));
          const status = "Error";
          alertMessage(message, status);
        });
    }
  };

  const handleLangs = (e) => {
    setLangs({ targetLang: e.target.value, displayedLang: e.target.value });

    let temp = props.paymentLink;

    for (let lang of langItems) {
      temp = temp.replace(`/${lang.value}/?`, `/${e.target.value}/?`);
    }

    props.setPaymentLink(temp);
  };
  const deletePlan = () => {
    delete_plan(props.plan)
      .then((res) => {
        const message = (t("Plan deleted"));
        const status = "Success";
        alertMessage(message, status);
      })
      .catch((err) => {
        const message = (t("There was an error. Please try again later..."));
        const status = "Error";
        alertMessage(message, status);
      });
  };
  // useEffect(() => {
  //     if (users?.planUsers?.length) {
  //     let tempActive = [];
  //     let tempInactive = [];
  //     for (let user of users.planUsers) {
  //         user?.isActive ? tempActive.push(user) : tempInactive.push(user);
  //     }
  //     setUsers({ activeUsers: tempActive, inactiveUsers: tempInactive });
  //     }
  // }, []);

  return (
    <SliderCurrentPlan>
      <Builder_ title={t("Unique plan url")}>
        <Clipboard>
          <p>{props.paymentLink}</p>
          <Grey_ iconLeft="Copy" onClick={() => copyLink()} />
        </Clipboard>
      </Builder_>
      <Section_Title text={t("Send link via email")} />
      <Builder_ title={t("Fill in the reciepient's email address")}>
        <Input_
          errors={[t("You must fill in this information")]}
          placeholder={t("Email address")}
          required
          onChange={(e) => setTargetEmail(e.target.value)}
        />
      </Builder_>
      <Builder_ title={t("Fill in the reciepient's name (optional)")}>
        <Input_
          placeholder={t("Name")}
          onChange={(e) => setTargetName(e.target.value)}
        />
      </Builder_>
      <Builder_ title={t("Select the language you wish the email to be sent")}>
        <Dropdown_
          items={langItems}
          value={langs.targetLang}
          onChange={(e) => handleLangs(e)}
        />
      </Builder_>
      <Actions_ end>
        <Main_Plain
          iconRight="Next"
          onClick={() => sendEmail()}
          text={t("Send email")}
        />
      </Actions_>
      <Section_Title text={t("Active subscribers")} />
      <CardsSixty>
        {props.subscribers?.activeUsers?.map((user) => {
          return (
            <On_Click_Card
              imgSmall="https://www.shutterstock.com/image-photo/surreal-image-african-elephant-wearing-260nw-1365289022.jpg"
              title={user.username}
              subtitle={`${user.firstname} ${user.lastname}`}
            ></On_Click_Card>
          );
        })}
      </CardsSixty>
      <Section_Title text={t("Inactive subscribers")} />
      <CardsSixty>
        {props.subscribers?.inactiveUsers?.map((user) => {
          return (
            <On_Click_Card
              imgSmall="https://www.shutterstock.com/image-photo/surreal-image-african-elephant-wearing-260nw-1365289022.jpg"
              title={user.username}
              subtitle={`${user.firstname} ${user.lastname}`}
            ></On_Click_Card>
          );
        })}
      </CardsSixty>
      <Actions_ end>
        <Red_ text={t("Delete")} onClick={() => deletePlan()} />
      </Actions_>
    </SliderCurrentPlan>
  );
}
