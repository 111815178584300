import { Card, Focus_, Focus_Plain, IconSvg, Main_, Main_Plain, Red_, device } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SubscriptionPlan = styled(Card)`
    padding: var(--largePads);
    
`

const SubscriptionPlanTop = styled.div`
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: flex-start;
    gap: 1rem;

    h6{
        color: var(--greyDark);
    }

    ul{

        li{
            display: flex;
            align-items: center;
            gap: .5rem;

            svg{
                width: 1.6rem;
                fill: var(--greyDark);
            }
        }
    }

    @media ${device.lg} {
        grid-template-columns: auto 1fr;
    }

    @media ${device.md} {
        grid-template-columns: auto 1fr;
    }
`

const SubscriptionPlanLeft = styled.div``

const SubscriptionPlanRight = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    gap: .5rem;
    
    .Price{
        display: flex;
        align-items: baseline;
    }

    @media ${device.lg} {
        grid-column: span 2;
    }

    @media ${device.md} {
        grid-column: span 2;
    }
`

const SubscriptionPlanCard = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
`

const SubscriptionPlanCardNo = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-bottom: 1rem;

    svg{
        width: 3rem;
        fill: ${p => p.theme.color};
    }
`

const SubscriptionPlanActions = styled.div`
    display: flex;
`

const SubscriptionPlanIcon = styled.div`
    width: 5rem;

    img{
        width: 100%;
        object-fit: contain;
    }
`

const SubscriptionPlanTrial = styled.h5``

const SubscriptionPlanFailed = styled.li`

    h6{
        color: var(--error) !important;
    }
    
    svg{
        width: 2rem !important;
        fill: var(--error) !important;
    }
`

export default function Subscription_Plan(props) {

    const {t} = useTranslation()

  return (
    <SubscriptionPlan>
        <SubscriptionPlanTop>
            <SubscriptionPlanIcon>
                <img src={props.img} />
            </SubscriptionPlanIcon>
            <SubscriptionPlanLeft>
                <h6>{t("Active plan")}</h6>
                <h2>{props.currentPlan}</h2>
                <ul>
                    <li>
                        <IconSvg Icon="Calendar" />
                        <p>{t("Days left")}:</p>
                        <h5>{props.daysLeft}</h5>
                        {props.trial ?
                            <SubscriptionPlanTrial>{t("on trial")}</SubscriptionPlanTrial>
                        :
                            null    
                        }
                    </li>
                    <li>
                        <IconSvg Icon="Payment" />
                        <p>{t("Next payment")}:</p>
                        <h5>{props.nextPayment}</h5>
                    </li>
                    {props.failed ?
                        <SubscriptionPlanFailed>
                            <IconSvg Icon="Tip" />
                            <h6>{t("last scheduled payment failed")}</h6>
                        </SubscriptionPlanFailed>
                    :
                        null    
                    }
                </ul>
            </SubscriptionPlanLeft>
            <SubscriptionPlanRight>
                <div className='Price'>
                    <h1>{props.currency}</h1>
                    <h1>{props.price}</h1>
                </div>
                <p>+ {t("VAT")} / {t("month")}</p>
            </SubscriptionPlanRight>
        </SubscriptionPlanTop>
        <SubscriptionPlanCard>
            <SubscriptionPlanCardNo>
                <IconSvg Icon="Payment" />
                <h5>*</h5>
                <h5>{props.cardNo}</h5>
            </SubscriptionPlanCardNo>
            {!props?.hasCanceled ? <Main_Plain iconLeft="Edit" noPadding text="Payment method" onClick={props.onEditCard}/> : null}
        </SubscriptionPlanCard>
        <SubscriptionPlanActions>
            {props.hasCanceled ?
                <Focus_Plain text={t("Renew subscription")} noPadding iconLeft="Recycle" onClick={props.onRenew}/> 
            :
                <Red_ text={t("Cancel subscription")} noPadding iconLeft="Delete" onClick={props.onCancel}/>
            }
        </SubscriptionPlanActions>
    </SubscriptionPlan>
  )
}
